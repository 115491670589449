import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  toggle(event) {
    const { toggleClass } = this.element.dataset;
    const { addTarget, removeTarget } = event.currentTarget.dataset;

    document.
      querySelectorAll(addTarget).
      forEach(el => el.classList.add(toggleClass));

    document.
      querySelectorAll(removeTarget).
      forEach(el => el.classList.remove(toggleClass));
  }
}

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["checkboxAll", "checkbox"];

  initialize() {
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  connect() {
    this.checkboxAllTarget.addEventListener("change", this.toggle);
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener("change", this.refresh);
    });

    this.refresh();
  }

  disconnect() {
    this.checkboxAllTarget.removeEventListener("change", this.toggle);

    this.checkboxTargets.forEach(checkbox => {
      checkbox.removeEventListener("change", this.refresh);
    });
  }

  toggle(event) {
    event.preventDefault();

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = event.target.checked;
    });
  }

  refresh() {
    const checkboxes = this.checkboxTargets;

    const checkboxesChecked = this.
      checkboxTargets.
      filter(checkbox => checkbox.checked);

    this.checkboxAllTarget.checked = checkboxesChecked.length > 0;

    this.checkboxAllTarget.indeterminate = checkboxesChecked.length > 0 &&
      checkboxesChecked.length < checkboxes.length;
  }
}

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "colorPaletteBackgroundSettings",
    "imageBackgroundSettings"
  ];

  toggleBackgroundSettings(event) {
    let imageSettingsDisabled = event.currentTarget.value != "image";

    this.imageBackgroundSettingsTarget.disabled = imageSettingsDisabled;
    this.colorPaletteBackgroundSettingsTarget.disabled = !imageSettingsDisabled;
  }
}

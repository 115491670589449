import ApplicationController from "../../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["errors", "modal"];

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
  }

  disconnect() {
    this.modal.dispose();
  }

  index(event) {
    this.stimulate(
      "Admin::Garage::EligibilityCodes#index",
      { serializeForm: true },
      Number(event.currentTarget.dataset.page) || 1
    );
  }

  new() {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate("Admin::Garage::EligibilityCodes#new");
  }

  finalizeNew() {
    this.modal.show();
  }

  create() {
    this.stimulate(
      "Admin::Garage::EligibilityCodes#create",
      { serializeForm: true }
    );
  }

  finalizeCreate() {
    if (!this.errorsTarget.children.length) {
      this.modal.hide();
    }
  }

  reactivate(event) {
    this.stimulate(
      "Admin::Garage::EligibilityCodes#reactivate",
      event.currentTarget.dataset.eligibilityCodeId
    );
  }

  deactivate(event) {
    this.stimulate(
      "Admin::Garage::EligibilityCodes#deactivate",
      event.currentTarget.dataset.eligibilityCodeId
    );
  }

  destroy(event) {
    this.stimulate(
      "Admin::Garage::EligibilityCodes#destroy",
      event.currentTarget.dataset.eligibilityCodeId
    );
  }
}

import AdminController from "../application_controller";

export default class extends AdminController {
  static classes = ["hidden"];
  static targets = ["negativeNotice"];
  static values = { chainInvoicedUntil: String };

  toggleNotice() {
    this.
      stimulate(
        "Admin::Agreement#edit",
        { serializeForm: true },
        this.chainInvoicedUntilValue
      ).
      then(({ payload }) => {
        this.negativeNoticeTarget.classList.toggle(
          this.hiddenClass,
          !payload.showNotice
        );
      });
  }
}

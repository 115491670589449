import ApplicationController from "../../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static outlets = ["admin--day-time-intervals"];
  static targets = ["modal"];

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
  }

  disconnect() {
    this.modal.dispose();
  }

  show() {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate("Admin::ParkingPermit::Schedules#show");
  }

  finalizeShow() {
    this.adminDayTimeIntervalsOutlet.index();
    this.modal.show();
  }
}

import ApplicationController from "../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["modal"];

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
  }

  disconnect() {
    this.modal.dispose();
  }

  show() {
    this.stimulate("Admin::EventLog#show");
  }

  finalizeShow() {
    this.modal.show();
  }
}

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["option"];

  connect() {
    let selected = this.optionTargets.find(option => option.checked);

    if (selected) {
      let id = `${selected.value.replace("_", "-")}-tab`;
      let tab = document.getElementById(id);
      tab && tab.classList.remove("d-none");
    }
  }
}

import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = ["tab"];

  connect() {
    this.tabTargets.forEach(tab => {
      tab.addEventListener("shown.bs.tab", this.rememberActiveTab);
      tab.addEventListener("shown.bs.tab", this.refreshMarkdownEditors);
    });

    this.activeTab.dispatchEvent(new Event("click"));
    history.replaceState(null, null, " ");
  }

  disconnect() {
    this.tabTargets.forEach(tab => {
      tab.removeEventListener("shown.bs.tab", this.rememberActiveTab);
      tab.removeEventListener("shown.bs.tab", this.refreshMarkdownEditors);
    });
  }

  rememberActiveTab(event) {
    localStorage.setItem("activeTab", event.target.hash);
  }

  refreshMarkdownEditors(event) {
    document.
      querySelectorAll(`${event.target.hash} .CodeMirror`).
      forEach(el => el.CodeMirror.refresh());
  }

  get activeTab() {
    return this.tabFromUrl || this.tabFromLocalStorage || this.tabTargets[0];
  }

  get tabFromUrl() {
    return this.tabTargets.find(el => {
      return el.getAttribute("href") === location.hash;
    });
  }

  get tabFromLocalStorage() {
    return this.tabTargets.find(el => {
      return el.getAttribute("href") === localStorage.getItem("activeTab");
    });
  }
}

import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = ["submit"];

  initialize() {
    const { newActionPath } = this.element.dataset;

    if (location.pathname != newActionPath) {
      history.pushState({}, "", newActionPath);
    }
  }

  new() {
    this.submitTarget.disabled = true;
    this.stimulate();
  }
}

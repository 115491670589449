import ApplicationController from "../../application_controller";
import Sortable from "sortablejs";

export default class extends ApplicationController {
  connect() {
    super.connect();

    this.sortable = Sortable.create(this.element, {
      animation: 150,
      handle: ".sortable-handle"
    });
  }

  disconnect() {
    this.sortable.destroy();
  }

  reposition() {
    let infoSections = document.querySelectorAll("[data-info-section-id]");
    let ids = [...infoSections].map(e => e.dataset.infoSectionId);

    this.stimulate("Admin::Garage::InfoSections#reposition", ids);
  }
}

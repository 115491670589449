import ApplicationController from "../../../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["errors", "modal"];

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
  }

  disconnect() {
    this.modal.dispose();
  }

  new() {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate("Admin::Agreement::EligibilityCode::Assignments#new");
  }

  finalizeNew() {
    this.modal.show();
  }

  create() {
    this.stimulate(
      "Admin::Agreement::EligibilityCode::Assignments#create",
      { serializeForm: true }
    );
  }

  finalizeCreate() {
    if (!this.errorsTarget.children.length) {
      this.modal.hide();
    }
  }
}

import ApplicationController from "../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["percentInput", "errors", "modal", "form"];

  initialize() {
    this.focusPercentInput = this.focusPercentInput.bind(this);
  }

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
    this.modalTarget.addEventListener("shown.bs.modal", this.focusPercentInput);
  }

  disconnect() {
    this.modal.dispose();

    this.modalTarget.removeEventListener(
      "shown.bs.modal",
      this.focusPercentInput
    );
  }

  index() {
    this.stimulate("Admin::RevenueStreamCommissions#index", this.formTarget);
  }

  edit(event) {
    this.modalTarget.firstElementChild.innerHTML = "";

    this.stimulate(
      "Admin::RevenueStreamCommissions#edit",
      this.formTarget,
      event.currentTarget.dataset.id
    );
  }

  finalizeEdit() {
    this.modal.show();
  }

  update() {
    this.stimulate(
      "Admin::RevenueStreamCommissions#update",
      this.formTarget,
      { serializeForm: true }
    );
  }

  finalizeUpdate() {
    if (!this.errorsTarget.children.length) {
      this.modalTarget.addEventListener(
        "hidden.bs.modal",
        this.index.bind(this),
        { once: true }
      );

      this.modal.hide();
    }
  }

  focusPercentInput() {
    this.percentInputTarget.focus();
    const value = this.percentInputTarget.value;
    this.percentInputTarget.value = "";
    this.percentInputTarget.value = value;
  }
}

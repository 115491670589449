import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static outlets = ["progress-bar"];

  initialize() {
    this.consumer = this.application.consumer;
  }

  connect() {
    super.connect();

    this.subscription = this.consumer.subscriptions.create(
      { channel: "SpotSequence::ProgressChannel" },
      { received: this.statusUpdateReceived.bind(this) }
    );
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.subscription);
  }

  create() {
    this.
      stimulate("Admin::SpotSequences#create", { serializeForm: true }).
      then(({ payload }) => {
        if (payload.id) {
          this.stimulate("Admin::SpotSequences#show", payload.id);
        }
      });
  }

  updateDescription() {
    this.stimulate(
      "Admin::SpotSequences#update_description",
      { serializeForm: true }
    );
  }

  statusUpdateReceived(data) {
    const { id, status, progress } = data;

    if (status === "pending") {
      this.progressBarOutlet.percentValue = progress;
    } else {
      setTimeout(() => this.stimulate("Admin::SpotSequences#show", id), 700);
    }
  }
}

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  initialize() {
    this.navigate = this.navigate.bind(this);
  }

  connect() {
    this.element.addEventListener("click", this.navigate);
  }

  disconnect() {
    this.element.removeEventListener("click", this.navigate);
  }

  navigate(event) {
    if (!event.target.href) {
      event.preventDefault();
      const { href, target = "_self" } = this.element.dataset;
      open(href, target);
    }
  }
}

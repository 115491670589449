import ApplicationController from "../../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["errors", "modal", "personalNumberInput"];

  initialize() {
    this.focusPersonalNumberInput = this.focusPersonalNumberInput.bind(this);
  }

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);

    this.modalTarget.addEventListener(
      "shown.bs.modal",
      this.focusPersonalNumberInput
    );
  }

  disconnect() {
    this.modal.dispose();

    this.modalTarget.removeEventListener(
      "shown.bs.modal",
      this.focusPersonalNumberInput
    );
  }

  index(event) {
    let page = 1;

    if (event) {
      page = Object(event.currentTarget.dataset).page;
    }

    this.stimulate("Admin::User::Bans#index", page);
  }

  new() {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate("Admin::User::Bans#new");
  }

  finalizeNew() {
    this.modal.show();
  }

  create() {
    this.stimulate("Admin::User::Bans#create", { serializeForm: true });
  }

  finalizeCreate() {
    if (!this.errorsTarget.children.length) {
      this.modalTarget.addEventListener(
        "hidden.bs.modal",
        this.index.bind(this),
        { once: true }
      );

      this.modal.hide();
    }
  }

  destroy(event) {
    if (confirm(event.currentTarget.dataset.message)) {
      this.stimulate(
        "Admin::User::Bans#destroy",
        event.currentTarget.dataset.signedId
      );
    }
  }

  afterDestroy() {
    this.index();
  }

  focusPersonalNumberInput() {
    this.personalNumberInputTarget.focus();
    const value = this.personalNumberInputTarget.value;
    this.personalNumberInputTarget.value = "";
    this.personalNumberInputTarget.value = value;
  }
}

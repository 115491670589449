import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["colorInput", "textInput"];

  initialize() {
    this.syncToTextInput = this.syncToTextInput.bind(this);
    this.syncToColorInput = this.syncToColorInput.bind(this);
  }

  connect() {
    this.colorInputTarget.addEventListener("input", this.syncToTextInput);
    this.textInputTarget.addEventListener("input", this.syncToColorInput);
  }

  disconnect() {
    this.colorInputTarget.removeEventListener("input", this.syncToTextInput);
    this.textInputTarget.removeEventListener("input", this.syncToColorInput);
  }

  randomizeValue() {
    this.colorInputTarget.value = `#${this.hex(6)}`;
    this.colorInputTarget.dispatchEvent(new Event("input"));
  }

  hex(size) {
    return [...Array(size)].
      map(() => Math.floor(Math.random() * 16).toString(16)).
      join("");
  }

  syncToTextInput() {
    this.textInputTarget.value = this.colorInputTarget.value;
  }

  syncToColorInput() {
    this.colorInputTarget.value = this.textInputTarget.value;
  }
}

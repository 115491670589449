import ApplicationController from "./application_controller";
import { Tooltip } from "bootstrap";

export default class extends ApplicationController {
  connect() {
    let { title, placement, delayShow, delayHide } = this.element.dataset;

    this.tooltip = new Tooltip(this.element, {
      title: title || "",
      placement: placement || "top",
      html: true,
      delay: {
        show: delayShow || 0,
        hide: delayHide || 0
      }
    });
  }

  disconnect() {
    this.tooltip.dispose();
  }
}

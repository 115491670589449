import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { config: Object };

  initialize() {
    this.eventHandlers = Object.entries(this.configValue).map(([selector, config]) => { // eslint-disable-line max-len
      return () => {
        const targets = document.querySelectorAll(selector);

        for (const [attribute, mode] of Object.entries(config)) {
          targets.forEach(target => {
            let checked = this.element.checked;
            target[attribute] = mode === "match" ? checked : !checked;
          });
        }
      };
    });
  }

  connect() {
    this.eventHandlers.forEach(eventHandler => {
      this.element.addEventListener("change", eventHandler);
    });

    this.element.dispatchEvent(new Event("change"));
  }

  disconnect() {
    this.eventHandlers.forEach(eventHandler => {
      this.element.removeEventListener("change", eventHandler);
    });
  }
}

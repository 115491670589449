import ApplicationController from "../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["modal"];

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
  }

  disconnect() {
    this.modal.dispose();
  }

  index(event) {
    this.stimulate(
      `${this.reflexClass}#index`,
      Object(event.currentTarget.dataset).parkingPage || 1
    );
  }

  finalizeIndex() {
    this.modal.show();
  }
}

import ApplicationController from "../../../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static outlets = ["progress-bar"];
  static targets = ["errors", "modal"];

  initialize() {
    this.consumer = this.application.consumer;
  }

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);

    this.subscription = this.consumer.subscriptions.create(
      { channel: "EligibilityCode::Import::ProgressChannel" },
      { received: this.statusUpdateReceived.bind(this) }
    );
  }

  disconnect() {
    this.modal.dispose();
    this.consumer.subscriptions.remove(this.subscription);
  }

  new() {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate("Admin::Garage::EligibilityCode::Imports#new");
  }

  finalizeNew() {
    this.modal.show();
  }

  create() {
    this.
      stimulate(
        "Admin::Garage::EligibilityCode::Imports#create",
        { serializeForm: true }
      ).
      then(({ payload }) => {
        if (payload.id) {
          Object.assign(
            this.modal._config,
            { backdrop: "static", keyboard: false }
          );

          this.stimulate(
            "Admin::Garage::EligibilityCode::Imports#show",
            payload.id
          );
        }
      });
  }

  statusUpdateReceived(data) {
    const { id, status, progress } = data;

    if (status === "processing") {
      this.hasProgressBarOutlet &&
        (this.progressBarOutlet.percentValue = progress);
    } else {
      setTimeout(() => {
        Object.assign(this.modal._config, { backdrop: true, keyboard: true });

        this.
          stimulate("Admin::Garage::EligibilityCode::Imports#show", id).
          then(() => status === "complete" && this.stimulate());
      }, 700);
    }
  }
}

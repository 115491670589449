import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = ["registrationDate", "typeOfRequest"];

  connect() {
    super.connect();
    this.currentDateValue = this.registrationDateTarget.value;
  }

  toggle(event) {
    let newDateValue;

    if (event.target == this.currentTypeOfRequest) {
      newDateValue = this.currentDateValue;
    } else {
      newDateValue = new Date().toLocaleDateString("en-SE");
    }

    this.registrationDateTarget.value = newDateValue;
  }

  get currentTypeOfRequest() {
    return this.typeOfRequestTargets.find(option => {
      return option.dataset.current == "true";
    });
  }
}

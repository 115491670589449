import ApplicationController from "./application_controller";
import { Toast } from "bootstrap";

export default class extends ApplicationController {
  connect() {
    this.element.addEventListener("hidden.bs.toast", this.element.remove);
    this.toast = new Toast(this.element);
    this.toast.show();
  }

  disconnect() {
    this.toast.dispose();
  }
}

import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static outlets = ["bank-id--qr-codes"];
  static values = { returnPath: String };

  initialize() {
    this.show = this.show.bind(this);
  }

  connect() {
    super.connect();
    this.refreshTimer = setInterval(this.show, 2000);
  }

  disableAutoRefresh() {
    clearInterval(this.refreshTimer);
  }

  show() {
    if (document.body.classList.contains("stimulus-reflex-connected")) {
      this.
        stimulate("BankId::Order::Results#show").
        then(({ payload }) => {
          if (["failed", "complete"].includes(payload.status)) {
            this.disableAutoRefresh();

            if (this.hasBankIdQrCodesOutlet) {
              this.bankIdQrCodesOutlet.disableAutoRefresh();
            }
          }

          if (payload.status == "complete") {
            window.location.href = this.returnPathValue;
          }
        });
    }
  }
}

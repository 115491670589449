import ApplicationController from "./application_controller";
import { Popover } from "bootstrap";

export default class extends ApplicationController {
  connect() {
    let { title, content, placement } = this.element.dataset;

    this.popover = new Popover(this.element, {
      trigger: "manual",
      html: true,
      title: title || "",
      content: content,
      placement: placement || "right",
      offset: [0, 15]
    });
  }

  toggle() {
    this.popover.toggle();
  }

  hide() {
    this.popover.hide();
  }

  disconnect() {
    this.popover.dispose();
  }
}

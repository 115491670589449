import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static classes = ["hidden"];
  static targets = ["bar"];
  static values = { percent: Number };

  percentValueChanged(value) {
    this.barTarget.style.width = `${value}%`;
  }

  toggle(isVisible) {
    this.element.classList.toggle(this.hiddenClass, !isVisible);
  }
}

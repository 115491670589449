import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static outlets = ["bank-id--order--results"];

  create() {
    this.
      stimulate("BankId::AutoStarts#create").
      then(({ payload }) => {
        setTimeout(() => {
          window.location.href = payload.autoStartLink;
        }, 700);
      });
  }
}

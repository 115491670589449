import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static values = { id: Number };

  initialize() {
    this.consumer = this.application.consumer;
  }

  connect() {
    super.connect();

    this.subscription = this.consumer.subscriptions.create(
      { channel: "AgreementImport::StatusChannel", id: this.idValue },
      { received: this.statusUpdateReceived.bind(this) }
    );
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.subscription);
  }

  statusUpdateReceived() {
    this.stimulate();
  }
}

import ApplicationController from "../../../application_controller";

export default class extends ApplicationController {
  index(event) {
    this.stimulate(
      "Admin::Agreement::Builder::DebtorSearches#index",
      event.currentTarget.value
    );
  }
}

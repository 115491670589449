import ApplicationController from "./application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["errors"];

  connect() {
    super.connect();
    this.newSessionModal = Modal.getOrCreateInstance(this.newSessionModalElement); // eslint-disable-line max-len
    this.sessionsModal = Modal.getOrCreateInstance(this.sessionsModalElement);
  }

  disconnect() {
    if (this.element.contains(this.newSessionModalElement)) {
      this.newSessionModal.dispose();
    }

    if (this.element.contains(this.sessionsModalElement)) {
      this.sessionsModal.dispose();
    }
  }

  index() {
    this.stimulate(`${this.reflexClass}#index`);
  }

  finalizeIndex() {
    this.sessionsModal.show();
  }

  new() {
    this.newSessionModalElement.firstElementChild.innerHTML = "";
    this.stimulate(`${this.reflexClass}#new`);
  }

  finalizeNew() {
    this.newSessionModal.show();
  }

  create() {
    this.stimulate(`${this.reflexClass}#create`, { serializeForm: true });
  }

  finalizeCreate() {
    if (!this.errorsTarget.children.length) {
      this.newSessionModal.hide();
    }
  }

  update() {
    this.stimulate(`${this.reflexClass}#update`);
  }

  get newSessionModalElement() {
    return document.getElementById("new-parking-session-modal").parentElement;
  }

  get sessionsModalElement() {
    return document.getElementById("parking-sessions-modal").parentElement;
  }
}

import ApplicationController from "./application_controller";
import Dropzone from "dropzone";
import { DirectUpload } from "@rails/activestorage";

export default class extends ApplicationController {
  static classes = ["hidden"];
  static outlets = ["progress-circle"];

  static targets = [
    "addFileMessage",
    "errorMessage",
    "input",
    "successMessage"
  ];

  static values = { acceptedFiles: String, url: String };

  initialize() {
    ["upload", "completeUpload", "updateProgress"].forEach(func => {
      this[func] = this[func].bind(this);
    });
  }

  connect() {
    this.dropzone = new Dropzone(this.element, {
      accept: this.upload,
      acceptedFiles: this.acceptedFilesValue,
      autoQueue: false,
      maxFiles: 1,
      previewsContainer: false,
      url: this.urlValue
    });
  }

  disconnect() {
    this.dropzone.destroy();
  }

  upload(file) {
    this.file = file;
    this.status = "in_progress";
    new DirectUpload(this.file, this.urlValue, this).create(this.completeUpload); // eslint-disable-line max-len
  }

  set status(value) {
    switch (value) {
      case "in_progress":
        this.toggleMessage({
          "addFile": false,
          "success": false,
          "error": false
        });

        this.progressCircleOutlet.percentValue = 0;
        this.progressCircleOutlet.toggle(true);
        break;

      case "success":
        this.progressCircleOutlet.percentValue = 100;

        setTimeout(() => {
          this.progressCircleOutlet.toggle(false);
          this.toggleMessage({ "success": true });
        }, 700);

        break;

      case "error":
        this.progressCircleOutlet.toggle(false);
        this.toggleMessage({ "error": true });
        break;
    }
  }

  toggleMessage(settings) {
    for (const messageType in settings) {
      this[`${messageType}MessageTarget`].classList.toggle(
        this.hiddenClass,
        !settings[messageType]
      );
    }
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", this.updateProgress);
    this.file.status = "uploading";
    this.dropzone.emit("processing", this.file);
  }

  updateProgress(event) {
    this.progressCircleOutlet.percentValue = Math.round(
      (event.loaded / event.total) * 100
    );
  }

  completeUpload(error, blob) {
    let status = error ? "error" : "success";

    this.inputTarget.disabled = !blob;
    this.inputTarget.value = error ? null : blob.signed_id;
    this.file.status = status;
    this.status = status;
    this.dropzone.emit(status, this.file, error);
    this.dropzone.emit("complete", this.file);
  }
}

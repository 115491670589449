import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = [
    "connectionSettingsFieldset",
    "connectionTypeInput",
    "fallbackUnitSelect",
    "providerInput"
  ];

  connect() {
    [...this.providerInputTargets, ...this.connectionTypeInputTargets].
      filter(option => option.checked).
      forEach(option => option.dispatchEvent(new Event("input")));
  }

  toggleConnectionSettings(event) {
    let value = event.currentTarget.value;
    this.connectionSettingsFieldsetTarget.disabled = value == "parakey";
  }

  toggleFallbackUnitSelect(event) {
    let value = event.currentTarget.value;
    this.fallbackUnitSelectTarget.disabled = value == "internet";
  }
}

import ApplicationController from "../../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["modal", "searchInput"];

  initialize() {
    this.focusSearchInput = this.focusSearchInput.bind(this);
  }

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
    this.modalTarget.addEventListener("shown.bs.modal", this.focusSearchInput);
  }

  disconnect() {
    this.modal.dispose();

    this.modalTarget.removeEventListener(
      "shown.bs.modal",
      this.focusSearchInput
    );
  }

  index(event) {
    let page = 1;

    if (event) {
      page = event.currentTarget.dataset.page;
    } else {
      this.modalTarget.firstElementChild.innerHTML = "";
    }

    this.stimulate(
      "MyPage::Locations::Spots#index",
      { serializeForm: true },
      page
    );
  }

  finalizeIndex() {
    this.modal.show();
  }

  focusSearchInput() {
    this.searchInputTarget.focus();
  }
}

import ApplicationController from "./application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["modal"];
  static values = { options: Object };

  connect() {
    let { backdrop = true, focus = true, keyboard = true } = this.optionsValue;
    this.modal = new Modal(this.modalTarget, { backdrop, focus, keyboard });
  }

  disconnect() {
    this.modal.dispose();
  }

  show() {
    this.modal.show();
  }
}

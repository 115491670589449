import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static classes = ["hidden"];
  static targets = ["circle", "text"];
  static values = { percent: Number };

  percentValueChanged(value) {
    this.textTarget.innerText = `${value}%`;

    this.circleTarget.setAttribute(
      "stroke-dashoffset",
      this.circleTarget.getAttribute("stroke-dasharray") * value / 100
    );
  }

  toggle(isVisible) {
    this.element.classList.toggle(this.hiddenClass, !isVisible);
  }
}

import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = [
    "agreementDurationLimitEnabledFieldset",
    "agreementDurationLimitEnabledInput",
    "agreementDurationAndProlongationFieldset",
    "agreementDurationUnitInput",
    "agreementExpirationRemindersEnabledInput",
    "agreementProlongationRemindersEnabledInput",
    "billingTypeInput",
    "chargingEnabledInput",
    "chargingPricesFieldset",
    "daysToAgreementExpirationBeforeReminderInput",
    "daysToAgreementProlongationBeforeReminderInput",
    "eligibilityCodeLabelInput",
    "eligibilityCodeRequiredInput",
    "noticePeriodInput",
    "priceIndexerEnabledInput",
    "priceIndexerSettingsFieldset",
    "pricePerPermitFixedInput",
    "pricePerPermitInput",
    "prolongationSettingsFieldset"
  ];

  connect() {
    let inputs = [
      this.agreementDurationLimitEnabledInputTarget,
      this.agreementExpirationRemindersEnabledInputTarget,
      this.agreementProlongationRemindersEnabledInputTarget,
      this.eligibilityCodeRequiredInputTarget,
      this.priceIndexerEnabledInputTarget,
      this.billingTypeInputTargets.find(option => option.checked)
    ];

    if (this.hasChargingEnabledInputTarget) {
      inputs.push(this.chargingEnabledInputTarget);
    }

    inputs.forEach(i => i.dispatchEvent(new Event("input")));
  }

  toggleEligibilityCodeLabelInput(event) {
    this.eligibilityCodeLabelInputTarget.disabled =
      !event.currentTarget.checked;
  }

  toggleAgreementDurationLimitEnabledFieldset(event) {
    if (event.currentTarget.value == "subscription") {
      this.agreementDurationLimitEnabledInputTarget.checked = true;
      this.agreementDurationAndProlongationFieldsetTarget.disabled = false;
    }

    this.agreementDurationLimitEnabledFieldsetTarget.disabled =
      event.currentTarget.value == "subscription";
  }

  toggleAgreementDurationUnits(event) {
    this.agreementDurationUnitInputTargets[0].disabled =
      event.currentTarget.value != "subscription";

    if (event.currentTarget.value != "subscription") {
      this.agreementDurationUnitInputTargets[1].checked = true;
    }
  }

  togglePriceIndexingSettings(event) {
    this.priceIndexerSettingsFieldsetTarget.disabled =
      !event.currentTarget.checked;
  }

  toggleChargingPrices(event) {
    this.chargingPricesFieldsetTarget.disabled = !event.currentTarget.checked;
  }

  toggleDaysToAgreementExpirationBeforeReminderInput(event) {
    this.daysToAgreementExpirationBeforeReminderInputTarget.disabled =
      !event.currentTarget.checked;
  }

  toggleDaysToAgreementProlongationBeforeReminderInput(event) {
    this.daysToAgreementProlongationBeforeReminderInputTarget.disabled =
      !event.currentTarget.checked;
  }

  togglePriceIndexerEnabledInput(event) {
    let value = event.currentTarget.value;
    this.priceIndexerEnabledInputTarget.disabled = value == "subscription";

    this.priceIndexerSettingsFieldsetTarget.disabled =
      value == "subscription" || !this.priceIndexerEnabledInputTarget.checked;
  }

  toggleNoticePeriodInput(event) {
    this.noticePeriodInputTargets.forEach(i => {
      i.disabled = event.currentTarget.value == "subscription";

      if (event.currentTarget.value == "subscription") {
        this.noticePeriodInputTargets[0].checked = true;
      }
    });
  }

  toggleAgreementDurationAndProlongationFieldset(event) {
    this.agreementDurationAndProlongationFieldsetTarget.disabled =
      !event.currentTarget.checked;
  }

  togglePricePerPermitFixedInput(event) {
    this.pricePerPermitFixedInputTarget.disabled =
      event.currentTarget.value != "subscription";
  }

  togglePricePerPermitInput(event) {
    this.pricePerPermitInputTarget.disabled =
      event.currentTarget.value == "subscription";
  }
}

import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["livingInPropertyInput", "sparEnabledInput"];

  connect() {
    this.sparEnabledInputTarget.dispatchEvent(new Event("input"));
  }

  toggleLivingInPropertyInput(event) {
    this.livingInPropertyInputTarget.disabled = event.currentTarget.checked;
  }
}

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { source: String };

  initialize() {
    this.sync = this.sync.bind(this);
  }

  sync(event) {
    this.element.value = event.currentTarget.value;
  }

  connect() {
    this.sourceElement.addEventListener("input", this.sync);
  }

  disconnect() {
    this.sourceElement.removeEventListener("input", this.sync);
  }

  get sourceElement() {
    return document.getElementById(this.sourceValue);
  }
}

import ApplicationController from "../../../application_controller";

export default class extends ApplicationController {
  static outlets = ["my-page--locations--spots"];
  static targets = ["id", "name"];

  new() {
    this.myPageLocationsSpotsOutlet.index();
  }

  create(event) {
    this.idTarget.value = event.params.id;
    this.nameTarget.setAttribute("value", event.params.name);
    this.myPageLocationsSpotsOutlet.modal.hide();
  }
}

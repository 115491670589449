import { Controller } from "@hotwired/stimulus";
import StimulusReflex from "stimulus_reflex";
import debounced from "debounced";

debounced.initialize(["input"], { wait: 300 });

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  beforeReflex() {
    document.body.classList.add("wait");
  }

  afterReflex() {
    document.body.classList.remove("wait");
  }
}

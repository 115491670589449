import ApplicationController from "../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["errors", "modal"];

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
  }

  disconnect() {
    this.modal.dispose();
  }

  edit() {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate("MyPage::ParkingAgreement#edit");
  }

  finalizeEdit() {
    this.modal.show();
  }

  update() {
    this.stimulate("MyPage::ParkingAgreement#update", { serializeForm: true });
  }

  finalizeUpdate() {
    if (!this.errorsTarget.children.length) {
      this.modal.hide();
    }
  }
}

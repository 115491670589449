import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  initialize() {
    const { newActionPath } = this.element.dataset;

    if (location.pathname != newActionPath) {
      history.pushState({}, "", newActionPath);
    }
  }

  new() {
    this.stimulate(null, { serializeForm: true });
  }
}

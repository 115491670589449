import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static classes = ["hidden"];
  static targets = ["creditLink", "errorMessage", "processingMessage"];
  static values = { publicId: String };

  initialize() {
    this.consumer = this.application.consumer;
  }

  connect() {
    super.connect();

    this.subscription = this.consumer.subscriptions.create(
      { channel: "Invoice::CrediterChannel", public_id: this.publicIdValue },
      { received: this.statusUpdateReceived.bind(this) }
    );
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.subscription);
  }

  statusUpdateReceived() {
    this.stimulate();
  }

  destroy(event) {
    if (confirm(event.currentTarget.dataset.confirmationMessage)) {
      this.creditLinkTarget.remove();
      this.errorMessageTarget.remove();
      this.processingMessageTarget.classList.remove(this.hiddenClass);
      this.stimulate("Admin::Invoices#destroy", this.publicIdValue);
    }
  }
}

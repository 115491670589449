import ApplicationController from "./application_controller";
import EasyMDE from "easymde";

export default class extends ApplicationController {
  connect() {
    this.editor = new EasyMDE({
      element: this.element,
      initialValue: this.element.value,
      minHeight: "100px",
      spellChecker: false,
      status: false
    });
  }

  disconnect() {
    this.editor.toTextArea();
    delete this.editor;
  }
}

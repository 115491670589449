import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static classes = ["hidden"];
  static targets = ["input", "results", "spinner"];

  initialize() {
    [
      "preventInputBlur",
      "showResults",
      "hideResults",
      "clearResults",
      "hideSpinner"
    ].forEach(func => this[func] = this[func].bind(this));
  }

  connect() {
    super.connect();
    this.inputTarget.value = "";
    this.resultsTarget.addEventListener("mousedown", this.preventInputBlur);
    this.inputTarget.addEventListener("focus", this.showResults);
    this.inputTarget.addEventListener("blur", this.hideResults);
    this.inputTarget.addEventListener("blur", this.hideSpinner);
    this.inputTarget.addEventListener("input", this.clearResults);
  }

  disconnect() {
    this.resultsTarget.removeEventListener("mousedown", this.preventInputBlur);
    this.inputTarget.removeEventListener("focus", this.showResults);
    this.inputTarget.removeEventListener("blur", this.hideResults);
    this.inputTarget.removeEventListener("blur", this.hideSpinner);
    this.inputTarget.removeEventListener("input", this.clearResults);
  }

  preventInputBlur(event) {
    event.preventDefault();
  }

  showResults() {
    if (this.resultsTarget.innerHTML) {
      this.resultsTarget.classList.remove(this.hiddenClass);
    }
  }

  hideResults() {
    this.resultsTarget.classList.add(this.hiddenClass);
  }

  clearResults() {
    if (!this.inputTarget.value) {
      this.resultsTarget.innerHTML = "";
    }
  }

  showSpinner() {
    this.spinnerTarget.classList.remove(this.hiddenClass);
  }

  hideSpinner() {
    this.spinnerTarget.classList.add(this.hiddenClass);
  }

  get isInputFocused() {
    return this.inputTarget === document.activeElement;
  }

  beforeIndex() {
    this.hideResults();
    this.isInputFocused && this.showSpinner();
  }

  index(event) {
    if (event.currentTarget.value) {
      this.stimulate("Admin::Search#index", event.currentTarget.value);
    }
  }

  afterIndex() {
    this.hideSpinner();
    this.isInputFocused && this.showResults();
  }
}

import ApplicationController from "../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["modal"];
  static values = { status: String };

  initialize() {
    this.consumer = this.application.consumer;
  }

  connect() {
    super.connect();

    this.modal = new Modal(this.modalTarget, {
      backdrop: "static",
      keyboard: false
    });

    this.subscription = this.consumer.subscriptions.create(
      { channel: "PaymentCard::StatusChannel" },
      { received: this.statusUpdateReceived.bind(this) }
    );
  }

  disconnect() {
    this.modal.dispose();
    this.consumer.subscriptions.remove(this.subscription);
  }

  new() {
    this.stimulate("MyPage::PaymentCard#new");
  }

  finalizeNew() {
    this.modal.show();
  }

  show() {
    this.stimulate("MyPage::PaymentCard#show", this.statusValue);
  }

  finalizeShow() {
    this.statusValue = "";
    this.modal.hide();
  }

  statusUpdateReceived(data) {
    this.statusValue = data.status;
  }

  statusValueChanged() {
    this.statusValue && this.show();
  }
}

import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  index(event) {
    let page = 1;

    if (event && event.currentTarget.tagName == "A") {
      page = event.currentTarget.dataset.page;
    }

    this.stimulate("Admin::SimpleSpots#index", { serializeForm: true }, page);
  }
}

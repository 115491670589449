import ApplicationController from "./application_controller";
import { Tooltip } from "bootstrap";

export default class extends ApplicationController {
  static classes = ["hidden"];
  static targets = ["source", "checkMark"];

  initialize() {
    this.setClipboardData = this.setClipboardData.bind(this);
  }

  connect() {
    super.connect();
    this.element.addEventListener("copy", this.setClipboardData);

    this.tooltip = new Tooltip(this.checkMarkTarget, {
      title: this.element.dataset.message,
      trigger: "manual",
      placement: "right"
    });
  }

  disconnect() {
    this.element.removeEventListener("copy", this.setClipboardData);
    this.tooltip.dispose();
  }

  setClipboardData(event) {
    event.clipboardData.setData("text/plain", this.sourceContent);
    event.preventDefault();
  }

  get sourceContent() {
    return this.sourceTarget.textContent.replace(/\n/g, "");
  }

  copy() {
    document.execCommand("copy");
    this.copied();
  }

  copied() {
    clearTimeout(this.timeout);
    this.toggleCheckMark(true);
    setTimeout(() => this.tooltip.show(), 500);

    this.timeout = setTimeout(() => {
      this.toggleCheckMark(false);
      this.tooltip.hide();
    }, 2000);
  }

  toggleCheckMark(isVisible) {
    this.checkMarkTarget.classList.toggle(this.hiddenClass, !isVisible);
  }
}

import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["errors", "select"];

  index() {
    let lastCreatedId = this.lastCreatedId;
    delete this.lastCreatedId;
    this.stimulate("Admin::DayTimeIntervals#index", lastCreatedId);
  }

  create() {
    this.
      stimulate("Admin::DayTimeIntervals#create", { serializeForm: true }).
      then(({ payload }) => this.lastCreatedId = payload.id);
  }

  finalizeCreate() {
    if (!this.errorsTarget.children.length) {
      this.selectTargets.forEach(target => target.selectedIndex = 0);
      this.index();
    }
  }

  destroy(event) {
    this.stimulate(
      "Admin::DayTimeIntervals#destroy",
      event.currentTarget.dataset.dayTimeIntervalSgid
    );
  }

  afterDestroy() {
    this.index();
  }
}

import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = [
    "endsAtInput",
    "prolongationEnabledInput",
    "prolongationPeriodFieldset"
  ];

  connect() {
    this.prolongationEnabledInputTarget.dispatchEvent(new Event("input"));
  }

  toggleEndsAtInput(event) {
    this.endsAtInputTarget.disabled = event.currentTarget.checked;
  }

  toggleProlongationPeriodFieldset(event) {
    this.prolongationPeriodFieldsetTarget.disabled =
      !event.currentTarget.checked;
  }
}

import ApplicationController from "../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["errors", "modal", "summary"];

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
  }

  disconnect() {
    this.modal.dispose();
  }

  edit() {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate("Admin::ParkingAttendantNotes#edit");
  }

  finalizeEdit() {
    this.modal.show();
  }

  update() {
    this.stimulate(
      "Admin::ParkingAttendantNotes#update",
      { serializeForm: true }
    );
  }

  finalizeUpdate() {
    if (!this.errorsTarget.children.length) {
      this.modal.hide();
    }
  }

  updateSummary(event) {
    this.summaryTarget.textContent = event.target.value;
  }
}
